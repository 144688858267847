import React,{useState,useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from "axios";

const { REACT_APP_TAKEDA_API_URL } = process.env;

const ProtectedRoute = ({ path, component: Comp, userType,hcpType,sessionToken,data, ...rest }) => {

    const [isAuthenticated,setAuthenticated] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const sessionCheck = async (token) => {
        let data = {
            sessionToken: token
        };
        axios
        .post(REACT_APP_TAKEDA_API_URL + "check_session", data)
        .then(function (response) {
            setAuthenticated(true);
            setLoaded(true);  
        })
        .catch(function (error) {
            setLoaded(true);
            console.log(error.response);
        });
    }

    useEffect(() => {
        sessionCheck(sessionToken);
    },[sessionToken]);
    if (!loaded) return null;
    return (
      <Route
        exact
        path={path}
        {...rest}
        render={(props) => {
            if( userType === null){
                return <Redirect to="/login" />
            }else if(userType === "hcp"){
                if(sessionToken === null){
                    return <Redirect to="/login" />
                }else{
                    if(!isAuthenticated){
                        return <Redirect to="/login" />
                    }
                    if(hcpType === null){
                        return <Redirect to="/hcpselection" />
                    }
                    return <Comp {...props} {...data}/>
                }
            }else if(userType === "halk"){
                return <Comp {...props} {...data}/>
            }
        }}
      />
    );
  };


export default ProtectedRoute;
